import service from "@/utils/axios";
import {useRoute} from "vue-router";
import {reactive, Ref, toRefs} from "vue";

export interface IWindStu{
  integralBalance: number | null;   // 学生积分余额
  studentName: string | null;       // 学生名称
  avatar: string | null;            // 学生头像
  integralCount: number | null;     // 当前课次积分
  sex: number;
}

export interface IWindState{
  windTable: IWindStu[];
  windTop3: IWindStu[];
  windAfter3: IWindStu[];
}

export function windTableBill() {

  let stateRef = reactive(<IWindState>{
    windTable: [],
    windTop3: [],
    windAfter3: [],
  });


  let refData = toRefs(stateRef);
  const route = useRoute();

  const getWindList = async () => {
    let r: any = await service.get(`/dwart/an_teaching/classCourse/studentIntegralListEvents/${route.query.classId}/and/${route.query.scheduleId}`)
    stateRef.windTable = r;
    stateRef.windTop3 = r.splice(0, 3);
    stateRef.windAfter3 = r;
    console.log(stateRef)
  }


  const init = async () => {
    await getWindList();
  }


  return {
    init,
    refData,
  }
}
