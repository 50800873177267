import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wind-table" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "card scroll-bar-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "zhuozi"
}
const _hoisted_5 = { class: "top" }
const _hoisted_6 = {
  key: 0,
  class: "top2 topitem"
}
const _hoisted_7 = { class: "topava" }
const _hoisted_8 = { class: "sname" }
const _hoisted_9 = { class: "ic-jifen" }
const _hoisted_10 = {
  key: 1,
  class: "top1 topitem"
}
const _hoisted_11 = { class: "topava" }
const _hoisted_12 = { class: "sname" }
const _hoisted_13 = { class: "ic-jifen" }
const _hoisted_14 = {
  key: 2,
  class: "top3 topitem"
}
const _hoisted_15 = { class: "topava" }
const _hoisted_16 = { class: "sname" }
const _hoisted_17 = { class: "ic-jifen" }
const _hoisted_18 = {
  key: 0,
  class: "table"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_dw_avatar = _resolveComponent("dw-avatar")!
  const _component_empty_no_lesson = _resolveComponent("empty-no-lesson")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_close_icon, { style: {"top":"20px"} })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "title" }, null, -1)),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "caidai" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.windTable.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.windTop3.length > 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_dw_avatar, {
                          sex: _ctx.windTop3[1].sex,
                          img: _ctx.windTop3[1].avatar,
                          size: 96
                        }, null, 8, ["sex", "img"])
                      ]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.windTop3[1].studentName), 1),
                      _createElementVNode("div", _hoisted_9, [
                        _cache[0] || (_cache[0] = _createElementVNode("div", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.windTop3[1].integralBalance), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.windTop3.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_dw_avatar, {
                          sex: _ctx.windTop3[0].sex,
                          img: _ctx.windTop3[0].avatar,
                          size: 96
                        }, null, 8, ["sex", "img"])
                      ]),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.windTop3[0].studentName), 1),
                      _createElementVNode("div", _hoisted_13, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.windTop3[0].integralBalance), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.windTop3.length > 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_dw_avatar, {
                          sex: _ctx.windTop3[2].sex,
                          img: _ctx.windTop3[2].avatar,
                          size: 96
                        }, null, 8, ["sex", "img"])
                      ]),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.windTop3[2].studentName), 1),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.windTop3[2].integralBalance), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.windAfter3.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "table-title table-line" }, [
                      _createElementVNode("span", null, "排名"),
                      _createElementVNode("span", null, "学生姓名"),
                      _createElementVNode("span", null, "总积分")
                    ], -1)),
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.windAfter3, (i, k) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "table-line table-item",
                          key: k
                        }, [
                          _createElementVNode("span", null, _toDisplayString(k + 4), 1),
                          _createElementVNode("span", null, _toDisplayString(i.studentName), 1),
                          _createElementVNode("span", null, _toDisplayString(i.integralBalance), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createBlock(_component_empty_no_lesson, {
              key: 1,
              "img-path": require('@/assets/image/timetable/pic_nostudent@2x.png'),
              height: "250"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" 暂无学生哦~ ")
              ])),
              _: 1
            }, 8, ["img-path"]))
      ])
    ])
  ]))
}